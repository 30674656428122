import FirestoreModel from './FirestoreModel';

class AppSettings extends FirestoreModel {
    static collectionName = 'appSettings';
    static allowedFields = [
      'maintenanceMode',
      'version',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default AppSettings;
