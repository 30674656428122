import FirestoreModel from './FirestoreModel';

class ObservationDelta extends FirestoreModel {
    static collectionName = 'observationDeltas';
    static allowedFields = [
      'createdAtUrl',
      'deltaFields',
      'observationId',
      'type', // 'create', 'update'
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default ObservationDelta;
