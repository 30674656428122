// models/BaseModel.js

import { db } from '@/firebase-setup'
import { doc, onSnapshot } from 'firebase/firestore';
import FirestoreService from '../services/FirestoreService';

// Utility functions to deal with nested paths
function getNestedValue(obj, fieldPath) {
  const parts = fieldPath.split('.');
  let current = obj;

  for (const part of parts) {
    const match = part.match(/^(.*)\[\]$/); // Check for array notation

    if (match) {
      const key = match[1];
      current = (current[key] || []).map(item => item);
    } else {
      if (current === null || typeof current !== 'object') {
        return undefined;
      }
      current = current[part];
    }
  }

  return current;
}

function setNestedValue(obj, fieldPath, value) {
  const parts = fieldPath.split('.');
  let current = obj;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    const match = part.match(/^(.*)\[\]$/);

    if (match) {
      const key = match[1];
      if (!current[key]) {
        current[key] = [];
      }
      if (i === parts.length - 1) {
        current[key].push(value);
      } else {
        if (!Array.isArray(current[key])) {
          current[key] = [];
        }
        current = current[key];
      }
    } else {
      if (i === parts.length - 1) {
        current[part] = value;
      } else {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    }
  }
}

// Base model
class FirestoreModel {
  constructor(id = null, data = {}) {
    this.id = id;

    this.unsubscribe = null;

    // Initialize fields based on allowed fields
    const allowedFields = this.constructor.allowedFields.concat([
      'timeCreated',
      'timeUpdated',
    ])
    for (const fieldPath of allowedFields) {
      let value = getNestedValue(data, fieldPath);

      const match = fieldPath.match(/^(.*)\[\]$/);
      if (match && !Array.isArray(value)) {
        value = [];
      }

      setNestedValue(this, fieldPath, value !== undefined ? value : null);
    }
  }

  static async getAll() {
    const documents = await FirestoreService.fetchAllDocuments(this.collectionName);

    // Map over the fetched documents and instantiate them as models
    return documents.map(doc => new this(doc.id, doc));
  }

  static async getAllWhere(conditions = []) {
    const documents = await FirestoreService.fetchAllDocumentsWhere(this.collectionName, conditions);
    return documents.map(document => new this(document.id, document));
  }

  static async getById(id) {
    const data = await FirestoreService.fetchDocument(this.collectionName, id);
    return new this(id, data);
  }

  getDataToSave() {
    const dataToSave = {};

    // Prepare data based on allowed fields
    const allowedFields = this.constructor.allowedFields.concat([
      'timeCreated',
      'timeUpdated',
    ])
    for (const fieldPath of allowedFields) {
      const value = getNestedValue(this, fieldPath);
      setNestedValue(dataToSave, fieldPath, value !== undefined ? value : null);
    }

    return JSON.parse(JSON.stringify(dataToSave));
  }

  async save(forceOverwrite = false) {
    if (this.id) {
      const dataToSave = this.getDataToSave();
      await FirestoreService.saveDocument(this.constructor.collectionName, this.id, dataToSave, forceOverwrite);
    } else {
      await this.create()
    }

    return this
  }

  async create() {
    const dataToSave = this.getDataToSave();
    const allowedFields = this.constructor.allowedFields.concat([
      'timeCreated',
      'timeUpdated',
    ])
    const result = await FirestoreService.createDocument(this.constructor.collectionName, dataToSave);
    for (const fieldPath of allowedFields) {
      this[fieldPath] = result[fieldPath]
    }
    this.id = result.id;
  }

  getFirestoreRef() {
    return doc(db, this.constructor.collectionName, this.id)
  }

  startListening(callback) {
    if (!this.id) {
      return
    }
    this.stopListening()
    this.unsubscribe = onSnapshot(this.getFirestoreRef(), (doc) => {
      if (doc.exists()) {
        let data = doc.data();
        console.info('ingesting snapshot', this.constructor.collectionName, this.id)
        data = FirestoreService.convertTimestampsToDates(data);
        for (const fieldPath of this.constructor.allowedFields) {
          const value = getNestedValue(data, fieldPath);
          setNestedValue(this, fieldPath, value !== undefined ? value : null);
        }
        if (callback) {
          callback(this)
        }
      }
    });
    console.info('started listener', this.constructor.collectionName, this.id)
  }

  stopListening() {
    if (this.unsubscribe) {
      this.unsubscribe()
      console.info('stopped listener', this.constructor.collectionName, this.id)
    }
  }

  toJson() {
    const json = {};

    // Prepare data based on allowed fields
    const allowedFields = this.constructor.allowedFields.concat([
      'timeCreated',
      'timeUpdated',
    ])
    for (const fieldPath of allowedFields) {
      const value = getNestedValue(this, fieldPath);
      setNestedValue(json, fieldPath, value !== undefined ? value : null);
    }

    return json
  }
}

export default FirestoreModel;
